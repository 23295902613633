<template lang="html">
  
  <div class="">
    <img src="@/assets/logonewest.svg" alt="" id="logo">
  </div>
<h1>360 FeedBack Upitnik</h1>
  <!-- <h3>Test radi: {{user.name}}</h3> -->
  <div class="row">
    <div class="card mx-auto">
  <div class="card-body">

  <form @submit.prevent="submitFeedBackTest360this">

    <div class="form-group">
      <label for="collegue">Ispitanik za koga popunjavate upitnik:</label>
      <select name="collegue" id="collegue" v-model="collegue" >
         <option  v-for="collegue in usersSameCompany" v-bind:key="collegue.name">{{collegue.name}}</option>
      </select>
      </div>
    <div class="form-group">
      <label for="">Vi ste ispitaniku:</label>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="typeOfCollegue1" id="typeOfCollegue1" v-model="typeOfCollegue" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Podređeni
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="typeOfCollegue2" id="typeOfCollegue2" v-model="typeOfCollegue" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
        Nadređeni
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="typeOfCollegue2" id="typeOfCollegue3" v-model="typeOfCollegue" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
        Isti Organizacioni Nivo
        </label>
      </div>
  </div>


      <div class="form-group">
        <h3>1. Za zadatke za koje niko u timu nije spreman da ih samostalno obavi, pravovremeno bira zaposlenog koji pokazuje najveći potencijal za samostalno obavljanje posla.</h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans1a" id="ans1a" v-model="ans1" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans1b" id="ans1b" v-model="ans1" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans1c" id="ans1c" v-model="ans1" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans1d" id="ans1d" v-model="ans1" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans1e" id="ans1e" v-model="ans1" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>


      <div class="form-group">
        <h3>2. Adekvatno bira kanal komunikacije (email, telefonski razgovor, sastanak) u skladu sa temom i prirodom problema kojim se bavi. </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans2a" id="ans2a" v-model="ans2" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans2b" id="ans2b" v-model="ans2" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans2c" id="ans2c" v-model="ans2" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans2d" id="ans2d" v-model="ans2" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans2e" id="ans2e" v-model="ans2" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>


      <div class="form-group">
        <h3>3. Adekvatno se postavlja i reaguje na primedbe, žalbe i kritike – uvažava tako dobijene informacije i nastoji da na osnovu njih unapredi svoj pristup poslu. </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans3a" id="ans3a" v-model="ans3" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans3b" id="ans3b" v-model="ans3" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans3c" id="ans3c" v-model="ans3" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans3d" id="ans3d" v-model="ans3" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans3e" id="ans3e" v-model="ans3" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>

      <div class="form-group">
        <h3>4. Afirmiše zaposlene u svom timu, podstičući ih na samostalnost u radu.</h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans4a" id="ans4a" v-model="ans4" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans4b" id="ans4b" v-model="ans4" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans4c" id="ans4c" v-model="ans4" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans4d" id="ans4d" v-model="ans4" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans4e" id="ans4e" v-model="ans4" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>



      <div class="form-group">
        <h3>5. Aktivno sluša sagovornika u komunikaciji, a  zatim proverava da li je dobro razumeo sagovornika, kako bi obezbedio razumevanje u komunikaciji.</h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans5a" id="ans5a" v-model="ans5" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans5b" id="ans5b" v-model="ans5" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans5c" id="ans5c" v-model="ans5" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans5d" id="ans5d" v-model="ans5" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans5e" id="ans5e" v-model="ans5" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>


      <div class="form-group">
        <h3>6. Analizira greške u radu na delegiranim zadacima i evaluira spremnost zaposlenih za uspešno realizovanje zadatka.</h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans6a" id="ans6a" v-model="ans6" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans6b" id="ans6b" v-model="ans6" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans6c" id="ans6c" v-model="ans6" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans6d" id="ans6d" v-model="ans6" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans6e" id="ans6e" v-model="ans6" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>


      <div class="form-group">
        <h3>7. Analizira zadatke iz perspektive zahtevanih resursa (vreme, ljudi, finansije, materijalni resursi) za njihovo obavljanje i vrši adekvatnu pripremu tima.</h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans7a" id="ans7a" v-model="ans7" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans7b" id="ans7b" v-model="ans7" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans7c" id="ans7c" v-model="ans7" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans7d" id="ans7d" v-model="ans7" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans7e" id="ans7e" v-model="ans7" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>


      <div class="form-group">
        <h3>8. Bez obzira na prirodu teme i sagovornika (bilo da je komunikacija prijatna ili neprijatna), u komunikaciji zadržava stav Ja sam ok, Ti si ok. </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans8a" id="ans8a" v-model="ans8" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans8b" id="ans8b" v-model="ans8" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans8c" id="ans8c" v-model="ans8" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans8d" id="ans8d" v-model="ans8" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans8e" id="ans8e" v-model="ans8" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>


      <div class="form-group">
        <h3>9. Daje zaposlenima izazovne zadatke tako da im omogućuje prostor za razvoj.</h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans9a" id="ans9a" v-model="ans9" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans9b" id="ans9b" v-model="ans9" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans9c" id="ans9c" v-model="ans9" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans9d" id="ans9d" v-model="ans9" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans9e" id="ans9e" v-model="ans9" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>


      <div class="form-group">
        <h3>10. Delegira zadatke onim zaposlenima koji su spremni da ih samostalno realizuju obezbeđujući ličnu efikasnost i efikasnost organizacije.</h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans10a" id="ans10a" v-model="ans10" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans10b" id="ans10b" v-model="ans10" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans10c" id="ans10c" v-model="ans10" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans10d" id="ans10d" v-model="ans10" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans10e" id="ans10e" v-model="ans10" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>

      <div class="form-group">
        <h3>11. Delegira zadatke vodeći računa i o sposobnosti i o motivaciji zaposlenog za taj zadatak.</h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans11a" id="ans11a" v-model="ans11" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans11b" id="ans11b" v-model="ans11" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans11c" id="ans11c" v-model="ans11" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans11d" id="ans11d" v-model="ans11" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans11e" id="ans11e" v-model="ans11" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>

      <div class="form-group">
        <h3>12.  Deli informacije i daje adekvatne savete sa ciljem da pruži podršku drugima u uspešnom obavljanju posla. </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans12a" id="ans12a" v-model="ans12" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans12b" id="ans12b" v-model="ans12" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans12c" id="ans12c" v-model="ans12" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans12d" id="ans12d" v-model="ans12" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans12e" id="ans12e" v-model="ans12" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>13. Demonstrira spremnost da donese odluku koja negativno utiče na pojedince u timu ili deo poslovanja zarad ostvarivanja viših kompanijskih ciljeva.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans13a" id="ans13a" v-model="ans13" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans13b" id="ans13b" v-model="ans13" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans13c" id="ans13c" v-model="ans13" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans13d" id="ans13d" v-model="ans13" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans13e" id="ans13e" v-model="ans13" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>14. Donosi odluke kroz upoređivanje informacija iz više različitih izvora i predviđanje posledica.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans14a" id="ans14a" v-model="ans14" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans14b" id="ans14b" v-model="ans14" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans14c" id="ans14c" v-model="ans14" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans14d" id="ans14d" v-model="ans14" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans14e" id="ans14e" v-model="ans14" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>15.  Drži fokus zaposlenih korišćenjem šansi za buduće uspehe i rezultate, umesto na zaslugama za rezultate u prošlosti.
 </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans15a" id="ans15a" v-model="ans15" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans15b" id="ans15b" v-model="ans15" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans15c" id="ans15c" v-model="ans15" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans15d" id="ans15d" v-model="ans15" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans15e" id="ans15e" v-model="ans15" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>16.  Govori jasno.
 </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans16a" id="ans16a" v-model="ans16" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans16b" id="ans16b" v-model="ans16" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans16c" id="ans16c" v-model="ans16" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans16d" id="ans16d" v-model="ans16" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans16e" id="ans16e" v-model="ans16" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>17. Identifikuje i razume izazove, probleme, šanse i donosi efektivne odluke o pravcu aktivnosti koje želi da sprovede.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans17a" id="ans17a" v-model="ans17" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans17b" id="ans17b" v-model="ans17" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans17c" id="ans17c" v-model="ans17" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans17d" id="ans17d" v-model="ans17" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans17e" id="ans17e" v-model="ans17" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>18. Interesuje se za potrebe i želje zaposlenih i preduzima aktivnosti u skladu sa tim.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans18a" id="ans18a" v-model="ans18" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans18b" id="ans18b" v-model="ans18" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans18c" id="ans18c" v-model="ans18" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans18d" id="ans18d" v-model="ans18" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans18e" id="ans18e" v-model="ans18" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>19. Iznenadnim (ad hoc) zadacima pristupa konstruktivno, preuzima zadatak na sebe i određuje rok ili preuzeti zadatak delegira unutar tima.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans19a" id="ans19a" v-model="ans19" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans19b" id="ans19b" v-model="ans19" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans19c" id="ans19c" v-model="ans19" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans19d" id="ans19d" v-model="ans19" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans19e" id="ans19e" v-model="ans19" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>20.  Izražava poverenje u sposobnost zaposlenih da obave zadatak, tako što im pruža pomoć kada je traže, nakon čega im brzo vraća odgovornost za zadatak.
 </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans20a" id="ans20a" v-model="ans20" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans20b" id="ans20b" v-model="ans20" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans20c" id="ans20c" v-model="ans20" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans20d" id="ans20d" v-model="ans20" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans20e" id="ans20e" v-model="ans20" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>21. Jasno stavlja do znanja koji su parametri delegiranih odgovornosti, uključujući i davanje autoriteta za donošenje odluka i preduzimanje potrebnih akcija.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans21a" id="ans21a" v-model="ans21" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans21b" id="ans21b" v-model="ans21" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans21c" id="ans21c" v-model="ans21" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans21d" id="ans21d" v-model="ans21" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans21e" id="ans21e" v-model="ans21" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>22. Kada komunicira u pisanom obliku, daje pregledne i razumljive informacije.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans22a" id="ans22a" v-model="ans22" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans22b" id="ans22b" v-model="ans22" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans22c" id="ans22c" v-model="ans22" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans22d" id="ans22d" v-model="ans22" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans22e" id="ans22e" v-model="ans22" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>23. Kada komunicira usmeno gleda sagovornika u oči i pokazuje uvažavanje svojim telesnim stavom.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans23a" id="ans23a" v-model="ans23" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans23b" id="ans23b" v-model="ans23" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans23c" id="ans23c" v-model="ans23" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans23d" id="ans23d" v-model="ans23" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans23e" id="ans23e" v-model="ans23" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>24. Konstantno traži načine da kreira nove i inovativne načine unapređenja organizacije i dolaženja do cilja.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans24a" id="ans24a" v-model="ans24" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans24b" id="ans24b" v-model="ans24" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans24c" id="ans24c" v-model="ans24" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans24d" id="ans24d" v-model="ans24" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans24e" id="ans24e" v-model="ans24" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>25. Kritiku upućuje na ponašanje, zadržavajući pozitivan kontekst uvažavanja zaposlenog.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans25a" id="ans25a" v-model="ans25" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans25b" id="ans25b" v-model="ans25" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans25c" id="ans25c" v-model="ans25" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans25d" id="ans25d" v-model="ans25" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans25e" id="ans25e" v-model="ans25" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>26. Kritiku upućuje pravovremeno, bez odlaganja.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans26a" id="ans26a" v-model="ans26" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans26b" id="ans26b" v-model="ans26" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans26c" id="ans26c" v-model="ans26" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans26d" id="ans26d" v-model="ans26" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans26e" id="ans26e" v-model="ans26" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>27. Manje vremena provodi objašnjavajući svoje vrednosti, više vremena provodi ponašajući se u skladu sa njima.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans27a" id="ans27a" v-model="ans27" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans27b" id="ans27b" v-model="ans27" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans27c" id="ans27c" v-model="ans27" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans27d" id="ans27d" v-model="ans27" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans27e" id="ans27e" v-model="ans27" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>28. Na "loše" vesti reaguje kao na šansu za unapređenje, ne pokazuje pasivnost i nesposobnost za reakciju.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans28a" id="ans28a" v-model="ans28" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans28b" id="ans28b" v-model="ans28" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans28c" id="ans28c" v-model="ans28" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans28d" id="ans28d" v-model="ans28" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans28e" id="ans28e" v-model="ans28" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>29. Na početku nedelje raspoređuje ključne zadatke zaposlenima.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans29a" id="ans29a" v-model="ans29" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans29b" id="ans29b" v-model="ans29" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans29c" id="ans29c" v-model="ans29" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans29d" id="ans29d" v-model="ans29" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans29e" id="ans29e" v-model="ans29" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>30. Nakon delegiranja zadataka organizuje sastanke na kojima će pratiti njihovu realizaciju.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans30a" id="ans30a" v-model="ans30" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans30b" id="ans30b" v-model="ans30" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans30c" id="ans30c" v-model="ans30" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans30d" id="ans30d" v-model="ans30" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans30e" id="ans30e" v-model="ans30" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>31. Nakon delegiranja zadatka određuje koje informacije o izvršenju zadatka će pratiti.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans31a" id="ans31a" v-model="ans31" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans31b" id="ans31b" v-model="ans31" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans31c" id="ans31c" v-model="ans31" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans31d" id="ans31d" v-model="ans31" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans31e" id="ans31e" v-model="ans31" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>32. Nakon delegiranja, obezbeđuje adekvatnu podršku zaposlenom u radu na zadatku uključujući se kao facilitator i izvor neophodnih informacija.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans32a" id="ans32a" v-model="ans32" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans32b" id="ans32b" v-model="ans32" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans32c" id="ans32c" v-model="ans32" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans32d" id="ans32d" v-model="ans32" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans32e" id="ans32e" v-model="ans32" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>33. Obezbeđuje vidljivost veze između nagrada i postignutih rezultata zaposlenih.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans33a" id="ans33a" v-model="ans33" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans33b" id="ans33b" v-model="ans33" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans33c" id="ans33c" v-model="ans33" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans33d" id="ans33d" v-model="ans33" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans33e" id="ans33e" v-model="ans33" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>34. Obučava druge u procesu razvoja njihovih veština.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans34a" id="ans34a" v-model="ans34" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans34b" id="ans34b" v-model="ans34" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans34c" id="ans34c" v-model="ans34" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans34d" id="ans34d" v-model="ans34" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans34e" id="ans34e" v-model="ans34" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>35. Odluke donosi pravovremeno i zastupa ih sa jasnom argumentacijom.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans35a" id="ans35a" v-model="ans35" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans35b" id="ans35b" v-model="ans35" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans35c" id="ans35c" v-model="ans35" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans35d" id="ans35d" v-model="ans35" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans35e" id="ans35e" v-model="ans35" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>36. Odluke koje su višeg ili nižeg nivoa pravovremeno prenosi odgovarajućem donosiocu odluke.

  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans36a" id="ans36a" v-model="ans36" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans36b" id="ans36b" v-model="ans36" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans36c" id="ans36c" v-model="ans36" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans36d" id="ans36d" v-model="ans36" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans36e" id="ans36e" v-model="ans36" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>37. Održava adekvatne međuljudske odnose i kada je pod pritiskom.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans37a" id="ans37a" v-model="ans37" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans37b" id="ans37b" v-model="ans37" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans37c" id="ans37c" v-model="ans37" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans37d" id="ans37d" v-model="ans37" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans37e" id="ans37e" v-model="ans37" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>38. Organizuje posao tako da se vrednuju saradnja i postignuti ciljevi.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans38a" id="ans38a" v-model="ans38" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans38b" id="ans38b" v-model="ans38" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans38c" id="ans38c" v-model="ans38" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans38d" id="ans38d" v-model="ans38" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans38e" id="ans38e" v-model="ans38" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>39. Osigurava razumevanje u komunikaciji tako što proverava sa sagovornikom da li razume poruku.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans39a" id="ans39a" v-model="ans39" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans39b" id="ans39b" v-model="ans39" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans39c" id="ans39c" v-model="ans39" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans39d" id="ans39d" v-model="ans39" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans39e" id="ans39e" v-model="ans39" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>40. Otvoreno priznaje i naglašava zalaganje i doprinos pojedinaca uključenih u rad tima.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans40a" id="ans40a" v-model="ans40" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans40b" id="ans40b" v-model="ans40" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans40c" id="ans40c" v-model="ans40" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans40d" id="ans40d" v-model="ans40" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans40e" id="ans40e" v-model="ans40" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>41. Planira na mesečnom nivou tako što pravi preglede ključnih aktivnosti na nivou meseca.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans41a" id="ans41a" v-model="ans41" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans41b" id="ans41b" v-model="ans41" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans41c" id="ans41c" v-model="ans41" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans41d" id="ans41d" v-model="ans41" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans41e" id="ans41e" v-model="ans41" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>42. Pokazuje ličnu hrabrost da povede ljude oko sebe u jasno određenom pravcu.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans42a" id="ans42a" v-model="ans42" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans42b" id="ans42b" v-model="ans42" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans42c" id="ans42c" v-model="ans42" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans42d" id="ans42d" v-model="ans42" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans42e" id="ans42e" v-model="ans42" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>43. Pokazuje spremnost na dogovor u situacijama kada se ne slaže sa sagovornikom.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans43a" id="ans43a" v-model="ans43" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans43b" id="ans43b" v-model="ans43" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans43c" id="ans43c" v-model="ans43" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans43d" id="ans43d" v-model="ans43" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans43e" id="ans43e" v-model="ans43" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>44. Pomaže drugima u korigovanju njihove komunikacije, kako bi podigao standard komunikacije u kompaniji.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans44a" id="ans44a" v-model="ans44" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans44b" id="ans44b" v-model="ans44" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans44c" id="ans44c" v-model="ans44" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans44d" id="ans44d" v-model="ans44" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans44e" id="ans44e" v-model="ans44" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>45. Postavlja jasne timske prioritete, objašnjava značaj i postavlja precizne ciljeve i zadatke za koje je tim odgovoran.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans45a" id="ans45a" v-model="ans45" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans45b" id="ans45b" v-model="ans45" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans45c" id="ans45c" v-model="ans45" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans45d" id="ans45d" v-model="ans45" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans45e" id="ans45e" v-model="ans45" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>46. Postavlja nedeljne ciljeve na početku nedelje.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans46a" id="ans46a" v-model="ans46" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans46b" id="ans46b" v-model="ans46" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans46c" id="ans46c" v-model="ans46" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans46d" id="ans46d" v-model="ans46" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans46e" id="ans46e" v-model="ans46" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>47. Prati promenu situacija i u skladu sa novoprispelim podacima u pravom trenutku vrši promenu odluke.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans47a" id="ans47a" v-model="ans47" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans47b" id="ans47b" v-model="ans47" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans47c" id="ans47c" v-model="ans47" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans47d" id="ans47d" v-model="ans47" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans47e" id="ans47e" v-model="ans47" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>48. Prati rad na realizaciji zadatka tako što osigurava efikasnost i balansira resurse u skladu sa eventualnim promenama situacije ili okolnosti.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans48a" id="ans48a" v-model="ans48" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans48b" id="ans48b" v-model="ans48" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans48c" id="ans48c" v-model="ans48" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans48d" id="ans48d" v-model="ans48" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans48e" id="ans48e" v-model="ans48" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>49. Pravi akcione planove za realizaciju posla u kojima se jasno vide odgovornosti i ciljevi rada svake uključene osobe.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans49a" id="ans49a" v-model="ans49" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans49b" id="ans49b" v-model="ans49" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans49c" id="ans49c" v-model="ans49" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans49d" id="ans49d" v-model="ans49" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans49e" id="ans49e" v-model="ans49" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>50. Pravi realistične dugoročne/srednjoročne planove rada.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans50a" id="ans50a" v-model="ans50" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans50b" id="ans50b" v-model="ans50" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans50c" id="ans50c" v-model="ans50" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans50d" id="ans50d" v-model="ans50" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans50e" id="ans50e" v-model="ans50" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>

      <div class="form-group">
        <h3>51. Pravovremeno (još tokom planiranja aktivnosti) obaveštava druge koji su uključeni u aktivnost o vremenu realizacije njihovih zadataka i vrsti posla u koji se uključuju.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans51a" id="ans51a" v-model="ans51" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans51b" id="ans51b" v-model="ans51" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans51c" id="ans51c" v-model="ans51" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans51d" id="ans51d" v-model="ans51" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans51e" id="ans51e" v-model="ans51" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>52. Pravovremeno koristi pohvalu i kritiku, tako da zaposleni imaju doživljaj i poštovanja, i praćenja i vrednovanja njihovog rada.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans52a" id="ans52a" v-model="ans52" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans52b" id="ans52b" v-model="ans52" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans52c" id="ans52c" v-model="ans52" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans52d" id="ans52d" v-model="ans52" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans52e" id="ans52e" v-model="ans52" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>53. Pravovremeno obaveštava nadređene kad neadekvatni rezultati aktivnosti ugrožavaju ispunjavanje cilja na način da on lično ili tim nemaju kapacitet da reše situaciju.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans53a" id="ans53a" v-model="ans53" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans53b" id="ans53b" v-model="ans53" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans53c" id="ans53c" v-model="ans53" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans53d" id="ans53d" v-model="ans53" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans53e" id="ans53e" v-model="ans53" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>54. Pravovremeno ulazi u komunikaciju koja ima stresnu prirodu (niti je odlaže, niti ulazi naglo, pre postavke ciljeva).
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans54a" id="ans54a" v-model="ans54" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans54b" id="ans54b" v-model="ans54" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans54c" id="ans54c" v-model="ans54" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans54d" id="ans54d" v-model="ans54" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans54e" id="ans54e" v-model="ans54" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>55. Precizno identifikuje jake strane i oblasti za razvoj drugih.
   </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans55a" id="ans55a" v-model="ans55" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans55b" id="ans55b" v-model="ans55" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans55c" id="ans55c" v-model="ans55" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans55d" id="ans55d" v-model="ans55" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans55e" id="ans55e" v-model="ans55" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>56. Predlaže konstruktivna rešenja problema i traži odgovornost za rešenje problema.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans56a" id="ans56a" v-model="ans56" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans56b" id="ans56b" v-model="ans56" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans56c" id="ans56c" v-model="ans56" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans56d" id="ans56d" v-model="ans56" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans56e" id="ans56e" v-model="ans56" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>57. Preduzima potrebne akcije kako bi se donesena odluka sprovela u realnost.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans57a" id="ans57a" v-model="ans57" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans57b" id="ans57b" v-model="ans57" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans57c" id="ans57c" v-model="ans57" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans57d" id="ans57d" v-model="ans57" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans57e" id="ans57e" v-model="ans57" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>58. Prepoznaje koje odluke su u njegovoj nadležnosti i preuzima odgovornost za njih.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans58a" id="ans58a" v-model="ans58" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans58b" id="ans58b" v-model="ans58" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans58c" id="ans58c" v-model="ans58" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans58d" id="ans58d" v-model="ans58" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans58e" id="ans58e" v-model="ans58" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>59. Prilikom delegiranja zadataka proverava da li je zaposleni razumeo sve parametre delegiranog zadatka.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans59a" id="ans59a" v-model="ans59" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans59b" id="ans59b" v-model="ans59" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans59c" id="ans59c" v-model="ans59" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans59d" id="ans59d" v-model="ans59" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans59e" id="ans59e" v-model="ans59" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>60. Prioritete u radu određuje pravilno u odnosu na objektivne zahteve posla.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans60a" id="ans60a" v-model="ans60" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans60b" id="ans60b" v-model="ans60" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans60c" id="ans60c" v-model="ans60" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans60d" id="ans60d" v-model="ans60" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans60e" id="ans60e" v-model="ans60" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>61. Razmatra šta u okruženju predstavlja prepreku, a šta pomaže razvoju i organizuje celokupno okruženje tako da podstiče razvoj i nagrađuje pozitivnu promenu.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans61a" id="ans61a" v-model="ans61" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans61b" id="ans61b" v-model="ans61" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans61c" id="ans61c" v-model="ans61" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans61d" id="ans61d" v-model="ans61" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans61e" id="ans61e" v-model="ans61" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>62. Razume da se oko njega ljudi razlikuju prema onome šta ih dodatno motiviše i tim saznanjima se rukovodi u procesu podsticanja na ostvarivanje rezultata.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans62a" id="ans62a" v-model="ans62" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans62b" id="ans62b" v-model="ans62" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans62c" id="ans62c" v-model="ans62" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans62d" id="ans62d" v-model="ans62" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans62e" id="ans62e" v-model="ans62" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>63. Razvija direktno podređene za obavljanje novih zadataka.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans63a" id="ans63a" v-model="ans63" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans63b" id="ans63b" v-model="ans63" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans63c" id="ans63c" v-model="ans63" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans63d" id="ans63d" v-model="ans63" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans63e" id="ans63e" v-model="ans63" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>64. Redovno sa svakim zaposlenim u svom timu definiše razvojne ciljeve i evaluira učinke.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans64a" id="ans64a" v-model="ans64" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans64b" id="ans64b" v-model="ans64" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans64c" id="ans64c" v-model="ans64" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans64d" id="ans64d" v-model="ans64" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans64e" id="ans64e" v-model="ans64" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>65. Rukovodi ličnim primerom - ponaša se onako kako bi voleo da se i ostali oko njega ponašaju.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans65a" id="ans65a" v-model="ans65" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans65b" id="ans65b" v-model="ans65" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans65c" id="ans65c" v-model="ans65" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans65d" id="ans65d" v-model="ans65" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans65e" id="ans65e" v-model="ans65" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>66. Sa lakoćom gradi zajedničku viziju budućnosti sa ljudima koji ga okružuju.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans66a" id="ans66a" v-model="ans66" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans66b" id="ans66b" v-model="ans66" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans66c" id="ans66c" v-model="ans66" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans66d" id="ans66d" v-model="ans66" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans66e" id="ans66e" v-model="ans66" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>67. Sadržaj koji prenosi u komunikaciji obuhvata uvek relevantne i suštinske ideje, sa optimalnim nivoom detalja.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans67a" id="ans67a" v-model="ans67" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans67b" id="ans67b" v-model="ans67" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans67c" id="ans67c" v-model="ans67" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans67d" id="ans67d" v-model="ans67" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans67e" id="ans67e" v-model="ans67" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>68. Sistematski nastoji da se obezbedi isticanje i nagrađivanje ispunjenja ciljeva.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans68a" id="ans68a" v-model="ans68" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans68b" id="ans68b" v-model="ans68" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans68c" id="ans68c" v-model="ans68" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans68d" id="ans68d" v-model="ans68" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans68e" id="ans68e" v-model="ans68" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>69. Sposoban je da adekvatno upravlja motivacijom zaposlenih tako što prepoznaje i razume tuđa osećanja i teškoće, kao i drugačije perspektive i različitosti.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans69a" id="ans69a" v-model="ans69" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans69b" id="ans69b" v-model="ans69" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans69c" id="ans69c" v-model="ans69" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans69d" id="ans69d" v-model="ans69" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans69e" id="ans69e" v-model="ans69" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>70. Sposoban je da radi paralelno i sistematski na više zadataka, ne gubeći fokus i kontrolu.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans70a" id="ans70a" v-model="ans70" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans70b" id="ans70b" v-model="ans70" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans70c" id="ans70c" v-model="ans70" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans70d" id="ans70d" v-model="ans70" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans70e" id="ans70e" v-model="ans70" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>71. Stalno je informisan - uspostavlja procedure da bi permanentno bio informisan o izazovima i rezultatima delegiranih zadataka.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans71a" id="ans71a" v-model="ans71" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans71b" id="ans71b" v-model="ans71" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans71c" id="ans71c" v-model="ans71" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans71d" id="ans71d" v-model="ans71" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans71e" id="ans71e" v-model="ans71" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>72. Stvara atmosferu u kojoj svako daje više od onoga što je mislio da može.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans72a" id="ans72a" v-model="ans72" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans72b" id="ans72b" v-model="ans72" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans72c" id="ans72c" v-model="ans72" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans72d" id="ans72d" v-model="ans72" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans72e" id="ans72e" v-model="ans72" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>73. Sumira i rezimira glavne ideje i zaključke na kraju komunikacije.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans73a" id="ans73a" v-model="ans73" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans73b" id="ans73b" v-model="ans73" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans73c" id="ans73c" v-model="ans73" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans73d" id="ans73d" v-model="ans73" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans73e" id="ans73e" v-model="ans73" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>74. Svestan je potencijalnih rizika za odluku koju je doneo i preuzima odgovornost za prevenciju istih.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans74a" id="ans74a" v-model="ans74" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans74b" id="ans74b" v-model="ans74" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans74c" id="ans74c" v-model="ans74" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans74d" id="ans74d" v-model="ans74" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans74e" id="ans74e" v-model="ans74" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>75. Svestan je svoje uloge u kompaniji (šta kompanija od njega očekuje) i ponaša se u skladu sa tim.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans75a" id="ans75a" v-model="ans75" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans75b" id="ans75b" v-model="ans75" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans75c" id="ans75c" v-model="ans75" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans75d" id="ans75d" v-model="ans75" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans75e" id="ans75e" v-model="ans75" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>76. Traži fidbek da bi poboljšao učinak.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans76a" id="ans76a" v-model="ans76" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans76b" id="ans76b" v-model="ans76" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans76c" id="ans76c" v-model="ans76" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans76d" id="ans76d" v-model="ans76" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans76e" id="ans76e" v-model="ans76" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>77. Traži jasne i konkretne informacije o zadatku koji preuzima na sebe.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans77a" id="ans77a" v-model="ans77" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans77b" id="ans77b" v-model="ans77" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans77c" id="ans77c" v-model="ans77" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans77d" id="ans77d" v-model="ans77" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans77e" id="ans77e" v-model="ans77" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>78. U komuniikaciji unapred postavlja cilj koji je 'zdrav', odnosno u interesu svih strana u komunikaciji.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans78a" id="ans78a" v-model="ans78" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans78b" id="ans78b" v-model="ans78" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans78c" id="ans78c" v-model="ans78" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans78d" id="ans78d" v-model="ans78" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans78e" id="ans78e" v-model="ans78" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>79. U komunikaciji na poslu koristi rečnik, terminologiju i način govora koji odgovara poslovnom kontekstu.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans79a" id="ans79a" v-model="ans79" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans79b" id="ans79b" v-model="ans79" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans79c" id="ans79c" v-model="ans79" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans79d" id="ans79d" v-model="ans79" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans79e" id="ans79e" v-model="ans79" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>80. U najrazličitijim situacijama reaguje na način koji adekvatno utiče na druge – ne prepušta se trenutnom impulsu već daje primer konstruktivnog pristupa situacijama.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans80a" id="ans80a" v-model="ans80" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans80b" id="ans80b" v-model="ans80" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans80c" id="ans80c" v-model="ans80" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans80d" id="ans80d" v-model="ans80" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans80e" id="ans80e" v-model="ans80" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>81. U sklopu planiranja kalkuliše moguće različite scenarije događanja, te planira više alternativnih načina za ostvarivanje cilja.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans81a" id="ans81a" v-model="ans81" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans81b" id="ans81b" v-model="ans81" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans81c" id="ans81c" v-model="ans81" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans81d" id="ans81d" v-model="ans81" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans81e" id="ans81e" v-model="ans81" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>82. U slučaju odstupanja u realizaciji zadataka pravovremeno vrši pooštravanje kontrole kako bi se ostalo na zacrtanom kursu.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans82a" id="ans82a" v-model="ans82" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans82b" id="ans82b" v-model="ans82" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans82c" id="ans82c" v-model="ans82" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans82d" id="ans82d" v-model="ans82" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans82e" id="ans82e" v-model="ans82" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>83. U susretu sa problemima prvo se fokusira na traženje rešenja, umesto na traženje krivca.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans83a" id="ans83a" v-model="ans83" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans83b" id="ans83b" v-model="ans83" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans83c" id="ans83c" v-model="ans83" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans83d" id="ans83d" v-model="ans83" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans83e" id="ans83e" v-model="ans83" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>84. Ukoliko nema adekvatne resurse, ne prihvata delegirani zadatak uz jasnu argumentaciju i daje eventualne smernice za obavljanje zadatka koje su korisne naručiocu zadatka.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans84a" id="ans84a" v-model="ans84" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans84b" id="ans84b" v-model="ans84" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans84c" id="ans84c" v-model="ans84" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans84d" id="ans84d" v-model="ans84" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans84e" id="ans84e" v-model="ans84" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>85. Upoređuje rezultate i ishode delegiranih zadataka u odnosu na definisane planove.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans85a" id="ans85a" v-model="ans85" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans85b" id="ans85b" v-model="ans85" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans85c" id="ans85c" v-model="ans85" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans85d" id="ans85d" v-model="ans85" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans85e" id="ans85e" v-model="ans85" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>86. Upostavlja posvećenost ljudi u kompaniji - podstiče entuzijazam drugih sa kojima radi na zadatku.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans86a" id="ans86a" v-model="ans86" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans86b" id="ans86b" v-model="ans86" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans86c" id="ans86c" v-model="ans86" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans86d" id="ans86d" v-model="ans86" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans86e" id="ans86e" v-model="ans86" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>87. Usmerava komunikaciju tako što daje relevatne informacije, razjašnjava svrhu i značaj teme.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans87a" id="ans87a" v-model="ans87" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans87b" id="ans87b" v-model="ans87" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans87c" id="ans87c" v-model="ans87" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans87d" id="ans87d" v-model="ans87" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans87e" id="ans87e" v-model="ans87" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>88. Za relevatne ciljeve i projekte pravi detaljne akcione planove.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans88a" id="ans88a" v-model="ans88" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans88b" id="ans88b" v-model="ans88" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans88c" id="ans88c" v-model="ans88" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans88d" id="ans88d" v-model="ans88" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans88e" id="ans88e" v-model="ans88" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>89. Zadatke delegira precizno, tako što saopštava kriterijume uspeha na zadatku, ključne oblasti zadatka i vremenske okvire za izvršenje.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans89a" id="ans89a" v-model="ans89" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans89b" id="ans89b" v-model="ans89" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans89c" id="ans89c" v-model="ans89" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans89d" id="ans89d" v-model="ans89" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans89e" id="ans89e" v-model="ans89" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>90. Zapisuje sve novodobijene zadatke odmah, i odmah precizira vreme kada će ih realizovati.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans90a" id="ans90a" v-model="ans90" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans90b" id="ans90b" v-model="ans90" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans90c" id="ans90c" v-model="ans90" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans90d" id="ans90d" v-model="ans90" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans90e" id="ans90e" v-model="ans90" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>91. Zaposleni u njegovom timu poznaju kriterijume koje je potrebno da zadovolje kako bi bili nagrađeni za svoj rad.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans91a" id="ans91a" v-model="ans91" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans91b" id="ans91b" v-model="ans91" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans91c" id="ans91c" v-model="ans91" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans91d" id="ans91d" v-model="ans91" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans91e" id="ans91e" v-model="ans91" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>
<div class="form-group">
        <h3>92. Zna kada da vodi i obučava druge, a kada da ih pusti da rade sami.
  </h3>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans92a" id="ans92a" v-model="ans92" value="1">
          <label class="form-check-label" for="flexRadioDefault1">
          Uopste se ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans92b" id="ans92b" v-model="ans92" value="2">
          <label class="form-check-label" for="flexRadioDefault2">
            Donekle se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans92c" id="ans92c" v-model="ans92" value="3">
          <label class="form-check-label" for="flexRadioDefault3">
            I slažem se i ne slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans92d" id="ans92d" v-model="ans92" value="4">
          <label class="form-check-label" for="flexRadioDefault4">
            Delimično se slažem
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="ans92e" id="ans92e" v-model="ans92" value="5">
          <label class="form-check-label" for="flexRadioDefault5">
            Potpuno se slažem
          </label>
        </div>
      </div>

      <div class="submitDiv">
    <button class="btn btn-primary">Submit</button>
    </div>
  </form>
  </div>
    </div>
    </div>


</template>

<script>
import {mapActions,mapGetters} from 'vuex';

export default {
  computed: mapGetters(['user','usersSameCompany']),
  data(){
    return{
      name:"",
      boss:"",
      typeOfCollegue:"1",
      ans1:"1",
ans2:"1",
ans3:"1",
ans4:"1",
ans5:"1",
ans6:"1",
ans7:"1",
ans8:"1",
ans9:"1",
ans10:"1",
ans11:"1",
ans12:"1",
ans13:"1",
ans14:"1",
ans15:"1",
ans16:"1",
ans17:"1",
ans18:"1",
ans19:"1",
ans20:"1",
ans21:"1",
ans22:"1",
ans23:"1",
ans24:"1",
ans25:"1",
ans26:"1",
ans27:"1",
ans28:"1",
ans29:"1",
ans30:"1",
ans31:"1",
ans32:"1",
ans33:"1",
ans34:"1",
ans35:"1",
ans36:"1",
ans37:"1",
ans38:"1",
ans39:"1",
ans40:"1",
ans41:"1",
ans42:"1",
ans43:"1",
ans44:"1",
ans45:"1",
ans46:"1",
ans47:"1",
ans48:"1",
ans49:"1",
ans50:"1",
ans51:"1",
ans52:"1",
ans53:"1",
ans54:"1",
ans55:"1",
ans56:"1",
ans57:"1",
ans58:"1",
ans59:"1",
ans60:"1",
ans61:"1",
ans62:"1",
ans63:"1",
ans64:"1",
ans65:"1",
ans66:"1",
ans67:"1",
ans68:"1",
ans69:"1",
ans70:"1",
ans71:"1",
ans72:"1",
ans73:"1",
ans74:"1",
ans75:"1",
ans76:"1",
ans77:"1",
ans78:"1",
ans79:"1",
ans80:"1",
ans81:"1",
ans82:"1",
ans83:"1",
ans84:"1",
ans85:"1",
ans86:"1",
ans87:"1",
ans88:"1",
ans89:"1",
ans90:"1",
ans91:"1",
ans92:"1"

    };
  },
  methods:{
    ...mapActions(['submitFeedBackTest360','getProfile','getCollegues']),
    submitFeedBackTest360this(){
      var result = this.usersSameCompany.find(usr => {
        return usr.name === this.collegue
      });
      //console.log(result);
    //  console.log(this.username,this.password)
      // eslint-disable-next-line no-unused-vars
    let feedbackTestData = {
      ans1:this.ans1,
ans2:this.ans2,
ans3:this.ans3,
ans4:this.ans4,
ans5:this.ans5,
ans6:this.ans6,
ans7:this.ans7,
ans8:this.ans8,
ans9:this.ans9,
ans10:this.ans10,
ans11:this.ans11,
ans12:this.ans12,
ans13:this.ans13,
ans14:this.ans14,
ans15:this.ans15,
ans16:this.ans16,
ans17:this.ans17,
ans18:this.ans18,
ans19:this.ans19,
ans20:this.ans20,
ans21:this.ans21,
ans22:this.ans22,
ans23:this.ans23,
ans24:this.ans24,
ans25:this.ans25,
ans26:this.ans26,
ans27:this.ans27,
ans28:this.ans28,
ans29:this.ans29,
ans30:this.ans30,
ans31:this.ans31,
ans32:this.ans32,
ans33:this.ans33,
ans34:this.ans34,
ans35:this.ans35,
ans36:this.ans36,
ans37:this.ans37,
ans38:this.ans38,
ans39:this.ans39,
ans40:this.ans40,
ans41:this.ans41,
ans42:this.ans42,
ans43:this.ans43,
ans44:this.ans44,
ans45:this.ans45,
ans46:this.ans46,
ans47:this.ans47,
ans48:this.ans48,
ans49:this.ans49,
ans50:this.ans50,
ans51:this.ans51,
ans52:this.ans52,
ans53:this.ans53,
ans54:this.ans54,
ans55:this.ans55,
ans56:this.ans56,
ans57:this.ans57,
ans58:this.ans58,
ans59:this.ans59,
ans60:this.ans60,
ans61:this.ans61,
ans62:this.ans62,
ans63:this.ans63,
ans64:this.ans64,
ans65:this.ans65,
ans66:this.ans66,
ans67:this.ans67,
ans68:this.ans68,
ans69:this.ans69,
ans70:this.ans70,
ans71:this.ans71,
ans72:this.ans72,
ans73:this.ans73,
ans74:this.ans74,
ans75:this.ans75,
ans76:this.ans76,
ans77:this.ans77,
ans78:this.ans78,
ans79:this.ans79,
ans80:this.ans80,
ans81:this.ans81,
ans82:this.ans82,
ans83:this.ans83,
ans84:this.ans84,
ans85:this.ans85,
ans86:this.ans86,
ans87:this.ans87,
ans88:this.ans88,
ans89:this.ans89,
ans90:this.ans90,
ans91:this.ans91,
ans92:this.ans92,
id:result._id,
typeOfCollegue:this.typeOfCollegue,
      name:this.user.name,
    };



    this.submitFeedBackTest360(feedbackTestData)
    .then(res=>{
      if(res.data.success){
        //ZA validation
        let validationName=result.name;
        localStorage.setItem('validationName', validationName);


        //////
        this.$router.push('/profile');
      }
    }).catch(err =>{
      console.log(err);
    });
    }
  },
  async created(){
    await this.getProfile();
    await this.getCollegues(this.user.type);

  }
};
</script>

<style lang="css" scoped>
h1{
  text-align: center;
  margin: 15px;
}
.submitDiv{
  width: 100%;
}
.btn{
  width: 100%;
  background-color: #003366;
}
.form-group{
  background-color: #bbffbb;
  padding: 15px;
}
.form-group:nth-child(2),
.form-group:nth-child(4),
.form-group:nth-child(6),
.form-group:nth-child(8),
.form-group:nth-child(10),
.form-group:nth-child(12),
.form-group:nth-child(14),
.form-group:nth-child(16),
.form-group:nth-child(18),
.form-group:nth-child(20),
.form-group:nth-child(22),
.form-group:nth-child(24),
.form-group:nth-child(26),
.form-group:nth-child(28),
.form-group:nth-child(30),
.form-group:nth-child(32),
.form-group:nth-child(34),
.form-group:nth-child(36),
.form-group:nth-child(38),
.form-group:nth-child(40),
.form-group:nth-child(42),
.form-group:nth-child(44),
.form-group:nth-child(46),
.form-group:nth-child(48),
.form-group:nth-child(50),
.form-group:nth-child(52),
.form-group:nth-child(54),
.form-group:nth-child(56),
.form-group:nth-child(58),
.form-group:nth-child(60),
.form-group:nth-child(62),
.form-group:nth-child(64),
.form-group:nth-child(66),
.form-group:nth-child(68),
.form-group:nth-child(70),
.form-group:nth-child(72),
.form-group:nth-child(74),
.form-group:nth-child(76),
.form-group:nth-child(78),
.form-group:nth-child(80),
.form-group:nth-child(82),
.form-group:nth-child(84),
.form-group:nth-child(86),
.form-group:nth-child(88),
.form-group:nth-child(90),
.form-group:nth-child(92)
{
  background-color: #bbffff;
  padding: 15px;
}
.container {



  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas:
    ". .";
  align-content: space-evenly;
}

</style>
